import React from "react"
import { Section, Container } from "./styled"

const PageHdr = ({ title }) => {
  return (
    <Section>
      <div>
        <Container className="main-page">
          <h1>{title}</h1>
        </Container>
      </div>
    </Section>
  )
}

export default PageHdr
