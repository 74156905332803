import React from "react"
import HighlightedLink from "../../HighlightedLink"
import { Container } from "./styled"

const Content = () => {
  return (
    <Container>
      <div className="heading">
        Free One-Year Maintenance Plan* With Complete System Purchase
      </div>
      <div>
        With our maintenance agreement, you’ll have the peace of mind that your
        air conditioning system is always in great shape. But if you do ever
        have an emergency, we offer our service agreement customers 24/7
        emergency services with no extra fees. During maintenance, our service
        technicians take the appropriate steps to ensure your HVAC system is
        operating as effectively and safely as possible.
      </div>
      <div className="subheading">
        Promo will automatically be added at Checkout
      </div>

      <HighlightedLink to="/buy/1" name="Get Started" />

      <div className="disclaimer">
        *Includes two (2) system tune-ups within 18 months from date of
        installation.
      </div>
    </Container>
  )
}

export default Content
