import styled from "styled-components"

export const Section = styled.section`
  background-color: #2e77b2;
  width: 100%;
  padding: 40px 0;

  @media print {
    display: none;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`
