import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin: 5% 15%;
  padding: 5%;
  border: 10px solid #6daa29;

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 2%;
    border: none;
  }

  & > div {
    text-align: center;
    margin: 10px 0;
  }

  & > div:first-child {
    margin-top: 0;
  }

  & > div:last-child {
    margin-bottom: 0;
  }

  & > div.logo img {
    max-width: 250px;
    height: auto;
  }

  & > div.heading {
    font-size: 36px;
    line-height: 36px;
    font-weight: 600;
    color: #154d7c;

    @media screen and (max-width: 1240px) {
      font-size: 28px;
      line-height: 28px;
    }
  }

  & > div.subheading {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: #154d7c;

    @media screen and (max-width: 1240px) {
      font-size: 18px;
      line-height: 18px;
    }
  }

  & > div.disclaimer {
    font-size: 14px;
    line-height: 16px;
  }
`
