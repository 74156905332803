import React from "react"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Content from "../components/content/Special"
import Footer from "../components/Footer"
import { Main, MainContainer } from "../elements/Layout/styled"

const Special = () => {
  return (
    <Layout>
      <Helmet
        name="Current Special"
        description="Free one year maintenance plan with a complete system purchase."
        slug="special"
      />
      <Header />
      <PageHdr title="Current Special" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <div>
              <Content />
            </div>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Special
