import styled from "styled-components"

export const Main = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 40px 0;

  @media screen and (max-width: 767px) {
    padding: 25px 0;
  }
`

export const MainContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  & h2 {
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
      line-height: 32px;
      text-align: center;
    }
  }

  & h3 {
    @media screen and (max-width: 767px) {
      font-size: 1.25rem;
      line-height: 28px;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  & thead th {
    background-color: #eee;
  }

  & th,
  td {
    text-align: center;
    padding: 7.5px;
    border: 1px solid #ddd;
    font-size: 0.95rem;
  }

  & th.left {
    text-align: left;
  }

  & th.right {
    text-align: right;
  }

  & td.center {
    text-align: center;
  }

  & td.left {
    text-align: left;
  }

  & td.right {
    text-align: right;
  }

  & tfoot th {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
  }

  & small {
    font-size: 0.8rem;
  }
`

export const ButtonContainer = styled.div`
  padding: 0;
  width: auto;
  height: auto;
  margin: 0 auto;
  text-align: center;

  &.hidden {
    display: none;
  }
`

export const Select = styled.select`
  height: 32px;
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 4px;
  vertical-align: middle;
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  cursor: pointer;
`
